import { AccordionSingle, Button } from 'components'
import { useEffect, useState } from 'react'
import styles from './ChatAdditionalInfo.module.css'
import { ReactComponent as ArrowRightIcon } from 'assets/img/arrow_right.svg'
import { ColorDot } from 'components/ColorDot/ColorDot.styles'
import { Divider } from '@mui/material'
import { ReactComponent as CrossSvg } from 'assets/img/cross.svg'
import { CreateOrderModal } from 'pages/OrdersPage/CreateOrderModal/CreateOrderModal'
import { useGetAppealsQuery } from 'redux/features/appeals/appealsApiSlice'
import { useDispatch, useSelector } from 'react-redux'

import { StatusColor } from 'pages/OrdersPage/CreateOrderModal/constants'
import {
  setChatRefFlatData,
  setCurrentMsgListInStore,
} from 'redux/features/chats/chatsApiReducerOLD'
import { setResetCurrentChat } from 'redux/features/chats/chatsApiReducer'

interface Chat {
  name: string
  lastMessage: string
  lastMessageTime: Date
}

interface Props {
  data: any
  initiatedId?: any
  setCurrentChatToNull?: any
}

export const ChatAdditionalInfo = () => {
  const chatRefFlatData = useSelector((state: any) => state?.chat?.chatRefFlatData)
  const currentChatInfo = useSelector((state: any) => state?.chat?.currentChatInfo)
  const { flat_id, id: currentChatId, chat_type, responsible_manager_id } = currentChatInfo

  const [searchText, setSearchText] = useState('')

  const { data: ordersData, refetch: refetchAppeals, isLoading } = useGetAppealsQuery({ query: '' })
  // const { data: ordersData, refetch: refetchAppeals } = useGetAppealsQuery({ query: searchText })

  useEffect(() => {
    console.log('ordersData SOCKET: new_data', ordersData)
  }, [ordersData])

  const [ordersToShow, setOrdersToShow] = useState([])

  useEffect(() => {
    if (currentChatId) {
      setOrdersToShow(
        // ({ target }: any) => target?.flat == chatRefFlatData?.chatsDataFSM?.flat,
        ordersData?.filter(({ target }: any) => target?.flat == flat_id),
      )
    } else {
      setOrdersToShow(ordersData)
    }
    // Аквамарин Макарова 12 с4 к3, подъезд 2, кв 2
  }, [ordersData, currentChatId])

  const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value)
  }

  // const filteredChats = chats.filter((chat: any) =>
  //   chat.name.toLowerCase().includes(searchText.toLowerCase()),
  // )

  const dispatch = useDispatch()

  return (
    <div className={styles.main_container}>
      {true && (
        <div>
          <div className={styles.top_close_btn_container}>
            <Button
              appearance='container'
              className={styles.btn_close}
              onClick={() => {
                dispatch(setResetCurrentChat())
              }}
            >
              <CrossSvg />
            </Button>
          </div>

          <div className={styles.additional_info_container}>
            <AccordionSingle title={<span>Активные заявки</span>}>
              <div className={styles.accordion_body_wrapper}>
                {/* {ordersData?.map((orderObj: any) => { */}

                {isLoading && (
                  <span style={{ color: 'grey', width: '100%', textAlign: 'center' }}>
                    подождите...
                  </span>
                )}
                {ordersToShow?.map((orderObj: any) => {
                  const { id, status } = orderObj

                  return (
                    <CreateOrderModal
                      refetchAppeals={refetchAppeals}
                      key={id}
                      editData={orderObj}
                      isDisabled={false}
                      btnTitle={
                        <Button
                          appearance='container'
                          className={styles.btn_order}
                          key={id}
                          onClick={() => {
                            console.log('orderObj', orderObj)
                          }}
                        >
                          <ColorDot style={{ backgroundColor: StatusColor[status] }} />
                          <span>№{id}</span>
                          <ArrowRightIcon />
                        </Button>
                      }
                    />
                  )
                })}
              </div>
            </AccordionSingle>
            <Divider className={styles.divider} />
          </div>
        </div>
      )}

      <div className={styles.create_order_btn_container}>
        {responsible_manager_id && chat_type !== 'appeal' && (
          <CreateOrderModal
            isAppealFromChat={true}
            // withAddressFields={true}
            refetchAppeals={refetchAppeals}
            //  currentChat={currentChat}
            btnTitle={
              <Button appearance='primary' className={styles.btn_add_action}>
                Создать заявку
              </Button>
            }
          />
        )}
      </div>
    </div>
  )
}
