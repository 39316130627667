import { useContext, useEffect, useState } from 'react'
import styles from './SideMenuList.module.css'
import { Link, useLocation } from 'react-router-dom'
import type { MenuItemProps } from './SideMenuList.props'
import { ReactComponent as ArrowSvg } from 'layout/img/arrow.svg'
import cn from 'classnames'
import type { IMenuItem } from 'layout/Layout.props'
import { useSelector } from 'react-redux'
import { useSocket } from 'providers/socketProvider/useSocket'
import msgSound from 'assets/sounds/ring.mp3'

const MenuItem = ({ item, className }: MenuItemProps): JSX.Element => {
  const chatListInfo = useSelector((state: any) => state?.chat)
  const { total_unseen_messages_count } = chatListInfo

  const location = useLocation()
  const [activeLink, setActiveLink] = useState<string>('')
  const [isSeconLevelOpened, setIsSeconLevelOpened] = useState<boolean>(false)
  const isOpenedNav = useSelector((state: any) => state?.sidebar?.isOpenedNav)

  const [totalPrev, setTotalPrev] = useState<any>(total_unseen_messages_count)
  const audio = new Audio(msgSound)

  useEffect(() => {
    if (totalPrev < total_unseen_messages_count) {
      audio.play()
      setTotalPrev(total_unseen_messages_count)
    }
  }, [total_unseen_messages_count])

  useEffect(() => {
    setActiveLink(location.pathname)
  }, [location])

  return (
    <li
      key={item.path}
      className={cn(className, {
        [styles.active_link]: activeLink.includes(item.active_menu),
      })}
    >
      {item.path ? (
        <Link
          to={item.path}
          className={cn(styles.first_level_item, {
            [styles.first_level_item_closed]: !isOpenedNav,
          })}
        >
          <div className={styles.menuItemTxt}>
            {item.icon}
            {isOpenedNav && item.title}{' '}
            {item.title == 'Чаты' && isOpenedNav ? `(${total_unseen_messages_count})` : null}
            {!isOpenedNav && item.title == 'Чаты' && total_unseen_messages_count > 0 && (
              <div
                className={cn(styles.msgNumLabel, {
                  [styles.msgNumLabel_closed]: !isOpenedNav,
                })}
              >
                {total_unseen_messages_count}
              </div>
            )}
          </div>
        </Link>
      ) : item.children ? (
        <>
          <a
            className={styles.menu_wrapper_second_level}
            onClick={() => setIsSeconLevelOpened(!isSeconLevelOpened)}
          >
            <div className={styles.second_level_item}>
              {item.icon} {item.title}
            </div>
            <div
              className={cn(styles.menu_arrow, { [styles.menu_arrow_active]: isSeconLevelOpened })}
            >
              <ArrowSvg />
            </div>
          </a>
          <ul
            className={cn(styles.second_level_menu_wrapper, {
              [styles.second_level_menu_wrapper_active]: isSeconLevelOpened,
            })}
          >
            {item?.children?.map((o: any) => (
              <li
                key={o.path}
                className={cn(styles.second_level_sub_item, {
                  [styles.active_sub_menu]: location.pathname == o.path,
                })}
              >
                <Link
                  to={o.path}
                  className={cn(styles.second_level_sub_item, {
                    [styles.active_sub_menu]: location.pathname == o.path,
                  })}
                >
                  {o.title}
                </Link>
              </li>
            ))}
          </ul>
        </>
      ) : (
        item.title
      )}
    </li>
  )
}

export const SideMenuList = ({ menuItems }: { menuItems: IMenuItem[] }) => {
  const { socketRef } = useSocket()

  useEffect(() => {
    // if (!token) {
    //   return
    // }

    // socketRef.on('error', console.log)
    // socketRef.on('message_sent', messageSentLog)
    socketRef?.connect()

    return () => {
      // socketRef?.off('message_sent', messageSentLog)
      socketRef?.disconnect()
    }
  }, [])

  // const { socketRef, isConnected } = useContext<any>(SocketContext)
  // const token = useSelector(selectCurrentToken) || localStorage.token

  // useEffect(() => {
  //   if (!token && !socketRef && !socketRef?.connected) {
  //     return
  //   }

  //   socketRef.on('message_new', messageNewLog)

  //   return () => {
  //     socketRef.removeAllListeners()
  //     socketRef?.off('message_new', messageNewLog)
  //   }
  // }, [token, socketRef])

  // const messageNewLog = (data: any) => {
  //   console.log('%csocketRef.current', 'color: BROWN; background: #212529; font-size: 18px', data)
  //   // dispatch(setAddIncomingMsgInChat(data))
  //   // socketRef.current?.disconnect()
  //   // socketRef.current?.connect()
  // }

  return (
    <div className={styles.menu_wrapper}>
      <ul>
        {menuItems?.map((item: any) => (
          <MenuItem key={item.path} item={item} />
        ))}
      </ul>
    </div>
  )
}
