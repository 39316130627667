import { useEffect, useState } from 'react'
import styles from './ChatList.module.css'
import moment from 'moment'
import classNames from 'classnames'
import { useInView } from 'react-intersection-observer'
import { ChatStatus } from 'redux/features/chats/chatsApiSlice'
import { useDispatch, useSelector } from 'react-redux'
import {
  PAGE_SIZE_PREVIEW_IN_CHAT,
  setCurrentChat,
  setIsActiveChats,
  setSearchWord,
} from 'redux/features/chats/chatsApiReducer'
import { fetchMoreChatList, setMessageSeen } from 'providers/socketProvider/SocketProvider'
import { useSocket } from 'providers/socketProvider/useSocket'
import { SelectGhost } from 'components/Input/SelectGhost/SelectGhost'
import { ReactComponent as SearchIcon } from 'assets/img/search.svg'

interface Chat {
  name: string
  lastMessage: string
  lastMessageTime: Date
}

interface Props {
  chats: Chat[]
}

const optionsStatus = [
  {
    id: 1,
    // value: ChatStatus.OPEN,
    value: true,
    title: 'Доступные',
  },
  {
    id: 2,
    value: false,
    title: 'Прочие',
  },
]

export const ChatList = () => {
  const dispatch = useDispatch()
  const { socketRef } = useSocket()
  const [page, setPage] = useState(1)
  const chatListInfo = useSelector((state: any) => state?.chat?.chatListInfo)
  const { count, chat_list, is_active, search_string } = chatListInfo

  const { ref, inView, entry } = useInView({
    rootMargin: '100px',
    threshold: 0.01,
  })

  useEffect(() => {
    if ((inView && chat_list.length < count) || page == 0) {
      fetchMoreChatList(socketRef, page + 1, PAGE_SIZE_PREVIEW_IN_CHAT, is_active, search_string)
      setPage((prev) => ++prev)
    }
  }, [inView, is_active, search_string])

  return (
    <div className={styles.chat_list_container}>
      <div className={styles.chat_search_row} style={{ opacity: 1, padding: '0 18px' }}>
        {/* <h1>
          Page: {page},chat_list:{chat_list.length},count : {count}.. inView:{' '}
          {inView ? 'true' : 'false'}
        </h1> */}
        <SelectGhost
          onChangeHandler={(value: any) => {
            // setIsActiveChats(value)
            console.log('setIsActiveChatsStore', value)
            setPage(0)
            dispatch(setIsActiveChats(value))
          }}
          options={optionsStatus}
          defaultSelected={optionsStatus[0].value}
        />
      </div>
      {/* {isActiveChatsStore} */}
      <div className={styles.chat_search_row} style={{ display: 'flex' }}>
        <div className={styles.chat_search_input_container}>
          <SearchIcon />
          <input
            name='search'
            placeholder='Поиск по названию чата'
            value={search_string}
            onChange={(e) => {
              setPage(0)
              dispatch(setSearchWord(e.target.value))
            }}
          />
        </div>
      </div>

      <ul className={styles.chat_list}>
        {chat_list.map((chatItem: any) => {
          return <ChatListItem key={chatItem?.id} data={chatItem} />
        })}
        {/* 
        <h1 ref={ref} style={{ opacity: 1 }}>
          Page: {page},chat_list:{chat_list.length}, inView: {inView ? 'true' : 'false'}
        </h1> */}
      </ul>
    </div>
  )
}

const ChatListItem = ({ data }: any) => {
  const { socketRef } = useSocket()
  const dispatch = useDispatch()
  const { title, unseen_messages_count, last_message } = data
  const currentChatInfo = useSelector((state: any) => state?.chat?.currentChatInfo)
  const { id: currentChatId } = currentChatInfo

  return (
    <li
      className={classNames(styles.chat_item, {
        [styles.chat_item_active]: data.id === currentChatId,
      })}
      onClick={() => {
        // console.log('ACC Event performed correctly:уауау', data)
        // socketRef.emit('message_seen', { message_id: data?.reference_id }, (response: any) => {
        //   if (response.status === 'ok') {
        //     console.log('ACC Event performed correctly:', response)
        //   } else {
        //     console.error('ACC Error occurred:', response.error)
        //   }
        // })
        console.log('SOCKET: data _here Item', data)
        if (data.id !== currentChatId) {
          dispatch(setCurrentChat({ ...data, isReset: true }))
        }

        setMessageSeen(socketRef, data?.last_message?.id)
      }}
    >
      <div className={styles.chat_row}>
        <div className={styles.chat_item_name}>{title}</div>
        <div className={styles.chat_item_date}>{moment(last_message?.sent_at).format('HH:mm')}</div>
      </div>
      <div className={styles.chat_row}>
        <div className={styles.chat_item_msg}>{last_message ? last_message.text : ''}</div>
        {unseen_messages_count > 0 && (
          <div className={styles.chat_item_msg_num}>{unseen_messages_count}</div>
        )}
      </div>
    </li>
  )
}
