import { API_TAGS, apiSlice } from 'redux/api/auth/apiSlice'

export enum ChatStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export const chatsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getChatsList: builder.query<any, any>({
      query: ({ query, status, chat_type }: { query: string; status: any; chat_type: boolean }) => {
        return {
          url: 'api/chats/',
          params: {
            query,
            status,
            chat_type,
          },
        }
      },
      providesTags: [API_TAGS.CHAT_LIST_GET],
    }),

    updChat: builder.mutation({
      query: ({ chat_id, is_active, title, responsible, status }: any) => ({
        url: `api/chats/${chat_id}/`,
        method: 'PUT',
        body: {
          title,
          responsible,
          status,
          is_active,
        },
      }),
      invalidatesTags: [API_TAGS.CHAT_LIST_GET],
    }),
  }),
})

export const { useLazyGetChatsListQuery, useGetChatsListQuery, useUpdChatMutation } = chatsApiSlice
