import { createSlice, PayloadAction, current } from '@reduxjs/toolkit'
import { ChatStatus } from './chatsApiSlice'

export const PAGE_SIZE_MESSAGES_IN_CHAT = 20
export const PAGE_SIZE_PREVIEW_IN_CHAT = 20 // ! MUST be the same like pageSize on first load from backend

// interface IState {
//   currentChatMessage: any
//   chatRefFlatData: any
//   chatsData: any
//   chatsDataSearch: any
//   searchWordChatList: any
//   is_active_chats: ChatStatus
//   totalMsgNum: any
//   countMsg: number
//   isMsgListReset: boolean
//   triggerPage: any
//   totalPagesOnSockets: any
//   // currentChatMsgPaginator: any
// }

// const initialState: IState = {
const initialState: any = {
  chatListInfo: {
    // isLoading: false,
    search_string: '',
    is_active: true,
    chat_list: [],
    count: 0,
  },
  currentChatInfo: {
    messages: [],
    chat_type: null,
    id: null,
    is_active: null,
    last_message: null,
    reference_id: null,
    responsible_manager_id: null,
    title: null,
    unseen_messages_count: null,
  },
  total_unseen_messages_count: null,
}

const chatsReducer = createSlice({
  name: 'chats',
  initialState,
  reducers: {
    finishChat(state: any, action: PayloadAction<any>) {
      state.chatListInfo.chat_list = state.chatListInfo.chat_list.filter((chat: any) => {
        return chat.reference_id !== action.payload
      })
      chatsReducer.caseReducers.setResetCurrentChat(state)
    },
    setResetCurrentChat(state) {
      state.currentChatInfo = {
        messages: [],
        chat_type: null,
        id: null,
        is_active: null,
        last_message: null,
        reference_id: null,
        responsible_manager_id: null,
        title: null,
        unseen_messages_count: null,
      }
    },
    setChatList(state: any, action: PayloadAction<any>) {
      if (state.chatListInfo.chat_list.length === 0) {
        state.chatListInfo.chat_list = action.payload.chat_list
        state.chatListInfo.count = action.payload.count
      } else {
        state.chatListInfo.chat_list = [
          ...state.chatListInfo.chat_list,
          ...action.payload.chat_list,
        ]
        state.chatListInfo.count = action.payload.count
      }
      state.total_unseen_messages_count = action.payload.total_unseen_messages_count
    },
    setCurrentChat(state: any, action: PayloadAction<any>) {
      console.log('SOCKET: data _here store', action.payload?.unseen_messages_count)
      if (action.payload.isReset) {
        state.currentChatInfo = {
          page: 1,
          messages: [],
          chat_type: null,
          id: null,
          is_active: null,
          last_message: null,
          reference_id: null,
          responsible_manager_id: null,
          title: null,
          unseen_messages_count: null,
        }
      }
      // setTimeout(() => {
      if (action.payload.results) {
        // state.currentChatInfo = {
        //   ...state.currentChatInfo,
        //   messages: action.payload.results,
        //   count: action.payload.count,
        // }
        if (state.currentChatInfo.messages.length === 0) {
          state.currentChatInfo = {
            ...state.currentChatInfo,
            messages: action.payload.results,
            count: action.payload.count,
          }
        } else {
          state.currentChatInfo = {
            ...state.currentChatInfo,
            messages: [...state.currentChatInfo.messages, ...action.payload.results],
            count: action.payload.count,
            page: ++state.currentChatInfo.page,
          }
        }
      } else {
        state.currentChatInfo = { ...state.currentChatInfo, ...action.payload }
      }

      if (action.payload?.unseen_messages_count) {
        state.chatListInfo.chat_list.forEach((chat: any) => {
          if (chat.id == action.payload?.id) {
            chat.unseen_messages_count =
              chat.unseen_messages_count - action.payload?.unseen_messages_count
          }
        })

        state.total_unseen_messages_count =
          state.total_unseen_messages_count - action.payload?.unseen_messages_count
      }
      // unseen_messages_count
      // }, 1000)

      // const po = {
      //   chat_type: null,
      //   id: 63734,
      //   is_active: false,
      //   last_message: {
      //     id: 2745,
      //     chat_id: 63734,
      //     text: 'dsgsg',
      //     author: {},
      //     sent_at: '2024-08-23T06:25:28.709358Z',
      //   },
      //   reference_id: null,
      //   responsible_manager_id: null,
      //   title: 'Долина 2601532 2601 с1564 к51516, подъезд 456, кв 2',
      //   unseen_messages_count: 0,
      // }
    },

    setAddIncomingMsgInChat: (state, action) => {
      // upd preview in chat list
      state.chatListInfo.chat_list.forEach((chat: any) => {
        // console.log('UNSEEN', current(chat.unseen_messages_count))
        if (chat.id == action.payload.chat_id) {
          chat.last_message = action.payload
          chat.unseen_messages_count =
            Number(action.payload?.chat_id) !== Number(state.currentChatInfo.id)
              ? ++chat.unseen_messages_count
              : chat.unseen_messages_count
        }
      })

      let chatToUpdPositionToTheFirst = action.payload
      state.chatListInfo.chat_list = state.chatListInfo.chat_list.filter((chat: any) => {
        if (chat.id !== action.payload.chat_id) {
          return chat
        } else {
          chatToUpdPositionToTheFirst = chat
        }
      })

      // if (chatToUpdPositionToTheFirst) {
      state.chatListInfo.chat_list.unshift(chatToUpdPositionToTheFirst)
      // }

      // console.log('SOCKET: chat_status_new __ action.payload', action.payload)
      // const r = {
      //   attachments: [],
      //   author: {
      //     id: 35,
      //     identity: 38,
      //     first_name: 'Роман',
      //     last_name: 'Кабаргин',
      //     middle_name: 'Георгиевич',
      //   },
      //   chat_id: 63831,
      //   id: 2933,
      //   seen_by: [],
      //   sent_at: '2024-09-10T11:27:40.955689Z',
      //   text: 'Opi',
      // }

      // console.log(
      //   'SOCKET: chat_status_new __ action.payload',
      //   current(state.chatListInfo.chat_list[6]),
      // )
      // chat_type: 'appeal'
      // flat_id: 1080
      // id: 63826
      // is_active: true
      // last_message: null
      // reference_id: 592
      // responsible_manager_id: 6
      // title: 'Чат по заявке №199'
      // unseen_messages_count: 0

      // state.chatListInfo.chat_list.unshift(current(state.chatListInfo.chat_list[6])) // chatToUpdPositionToTheFirst
      // state.chatListInfo.chat_list.unshift(action.payload) // chatToUpdPositionToTheFirst

      // upd message list in current chat
      if (Number(action.payload?.chat_id) === Number(state.currentChatInfo.id)) {
        state.currentChatInfo = {
          ...state.currentChatInfo,
          messages: [...state.currentChatInfo.messages, action.payload],
          count: ++state.currentChatInfo.count,
        }
      }
    },

    setMsgSeen(state: any, action: PayloadAction<any>) {
      // state.chatListInfo.chat_list = state.chatListInfo.count - action.payload.unseen_messages_count
      // state.chatListInfo.total_unseen_messages_count =
      //   state.chatListInfo.count - action.payload.unseen_messages_count
      // const chat_id = action.payload
      // const index = state.chatsData.findIndex((obj: any) => obj.id === chat_id)
      // state.totalMsgNum = state.totalMsgNum - state.chatsData[index].unseen_messages_count
      // state.chatsData[index].unseen_messages_count = 0
    },

    setIsActiveChats: (state, action) => {
      state.chatListInfo.chat_list = []
      state.chatListInfo.count = 0
      state.chatListInfo.is_active = action.payload
    },

    setSearchWord: (state, action) => {
      state.chatListInfo.chat_list = []
      state.chatListInfo.count = 0
      state.chatListInfo.search_string = action.payload
    },
    updResponsibleForChat(state: any, action: PayloadAction<any>) {
      const { responsible, status, chat_id, is_active, reference } = action.payload
      // console.log('ACTION_PAYLOAD_responsible: ', responsible) // Новое сообщение

      state.currentChatInfo.responsible_manager_id = responsible
      state.currentChatInfo.is_active = is_active
    },
    setChatStatusNewSockets: (state, action) => {
      // state.chatListInfo.chat_list.forEach((chat: any) => {
      //   if (chat.id == action.payload.id) {
      //     console.log('SOCKET: chat_status_new  YA TUT', action.payload)
      //     chat.is_active = action.payload.is_active
      //     chat.responsible_manager_id = action.payload.responsible_manager_id
      //   }
      // })

      state.chatListInfo.chat_list = state.chatListInfo.chat_list.filter(
        (chat: any) => chat.id !== action.payload.id,
      )

      if (action.payload.is_active == false) {
        state.currentChatInfo = {
          messages: [],
          chat_type: null,
          id: null,
          is_active: null,
          last_message: null,
          reference_id: null,
          responsible_manager_id: null,
          title: null,
          unseen_messages_count: null,
        }
      }

      // console.log('SOCKET: chat_status_new  result', current(state.chatListInfo.chat_list))

      // chat_type: 'support'
      // flat_id: 389
      // id: 63726
      // is_active: false
      // last_message: null
      // reference_id: 492
      // responsible_manager_id: 6
      // title: 'Аквамарин Макарова 12 с4 к3, подъезд 2, кв 2'

      // state.chatsData = state.chatsData.map((obj: any) => {
      //   if (obj.id === action.payload.id) {
      //     return {
      //       ...obj,
      //       chatsDataFSM: {
      //         ...obj.chatsDataFSM,
      //         // is_active: false,
      //         is_active: action.payload.is_active,
      //         responsible: null,
      //         // responsible: action.payload.is_active === true ? action.payload.me : null,
      //         status: action.payload.is_active === true ? ChatStatus.OPEN : ChatStatus.CLOSED,
      //       },
      //     }
      //   } else {
      //     return obj
      //   }
      // })
    },
    // // setChatStatusNewSockets: (state, action) => {
    // //   console.log('chatStatusNew====', action.payload, current(state.chatsData))
    // //   state.chatsData = state.chatsData.map((obj: any) => {
    // //     if (obj.id === action.payload) {
    // //       return {
    // //         ...obj,
    // //         chatsDataFSM: {
    // //           ...obj.chatsDataFSM,
    // //           // is_active: false,
    // //           responsible: null,
    // //           status: ChatStatus.CLOSED,
    // //         },
    // //       }
    // //     } else {
    // //       return obj
    // //     }
    // //   })
    // //   state.chatRefFlatData.chatsDataFSM.responsible = null
    // // },
    // setChatsListData: (state, action) => {
    //   // if (state.chatsData.length > 0) return // TODO ! onChatsData re-renders and sets totalMsgNUm on every msg

    //   if (state.chatsData?.length <= 0) {
    //     console.log('TE_SAM_chats.setChatsListData', action.payload?.chats)
    //     state.chatsData = action.payload?.chats?.filter(
    //       (chat: any) => chat?.chatsDataFSM?.chat_type !== 'channel',
    //     )

    //     state.totalMsgNum = action.payload?.total_unseen_messages_count
    //   } else {
    //     const uniqueChats = [...current(state.chatsData), ...action.payload.chats].reduce(
    //       (acc, current) => {
    //         if (!acc.some((item: any) => item.id === current.id)) {
    //           acc.push(current)
    //         }
    //         return acc
    //       },
    //       [],
    //     )

    //     state.chatsData = [...uniqueChats]
    //     state.totalMsgNum = action.payload?.total_unseen_messages_count
    //   }
    // },

    // setUnseenMessages: (state, action) => {
    //   console.log('ACTION_PAYLOAD: ', action.payload) // Новое сообщение
    // },

    // updChatsPreviewData(state: any, action: PayloadAction<any>) {
    //   const { chat_id } = action.payload

    //   // Chats Objects
    //   const arrWithoutUpdChat = state.chatsData?.filter((chat: any) => chat.id != chat_id) || []
    //   const chatToUpdate = state.chatsData?.find((chat: any) => chat.id == chat_id)

    //   if (!chatToUpdate) {
    //     console.log(`Chat with id ${chat_id} not found`)
    //     return // Exit the reducer if the chat is not found
    //   }

    //   const updMessages = { ...current(chatToUpdate), last_message: action.payload }

    //   // спредим объект
    //   const objForUpdMsg = {
    //     ...updMessages,
    //     unseen_messages_count:
    //       Number(chat_id) === Number(state.chatRefFlatData?.chatsDataFSM?.reference)
    //         ? 0
    //         : (chatToUpdate.unseen_messages_count || 0) + 1,
    //   }

    //   const updChats = [objForUpdMsg, ...arrWithoutUpdChat]

    //   state.chatsData = updChats

    //   if (Number(chat_id) !== Number(state.chatRefFlatData?.chatsDataFSM?.reference)) {
    //     state.totalMsgNum = (state.totalMsgNum || 0) + 1
    //   }
    // },

    // setTriggerPage(state: any, action: PayloadAction<any>) {
    //   state.triggerPage = action.payload
    // },
    // setTotalPagesOnSockets(state: any, action: PayloadAction<any>) {
    //   state.totalPagesOnSockets = action.payload + 1
    // },
    // setCurrentMsgListInStore(state, action: PayloadAction<any>) {
    //   // if (!action.payload.results) {
    //   //   state.isMsgListReset = true
    //   //   state.currentChatMessage = []
    //   // } else {
    //   //   state.isMsgListReset = false
    //   //   state.currentChatMessage = [...current(state.currentChatMessage), ...action.payload.results]
    //   //   state.countMsg = action.payload.count
    //   // }
    // },
  },
})

export const {
  setResetCurrentChat,
  // setChatsListSearchData,
  finishChat,
  setMsgSeen,
  setChatList,
  setCurrentChat,
  setAddIncomingMsgInChat,
  setIsActiveChats,
  setSearchWord,
  setChatStatusNewSockets,
  updResponsibleForChat,
  // setAddIncomingMsgInChat,
  // setChatRefFlatData,
  // // setChatsData,
  // updChatsPreviewData,
  // setUnseenMessages,
  // setChatsListData,
  // setSearchWordChatList,
  // setIsActiveChatsStore,
  // setTriggerPage,
  // setTotalPagesOnSockets,
  // setCurrentMsgListInStore,
} = chatsReducer.actions
export default chatsReducer.reducer
