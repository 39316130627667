import styles from './MsgContainer.module.css'
import cn from 'classnames'
import moment from 'moment'
import { Button, Dropdown, Title } from 'components'
import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as SearchIcon } from 'assets/img/search.svg'
import { ReactComponent as SendArrow } from './img/arrow_send.svg'
import { ReactComponent as CrossSvg } from 'assets/img/cross.svg'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Modal } from '@mui/material'
import { ChatStatus, useUpdChatMutation } from 'redux/features/chats/chatsApiSlice'

import { SendFileClip } from './SendFileClip/SendFileClip'
import { ReactComponent as ClipSvg } from 'assets/img/clip_send_file.svg'
// import { ReactComponent as DotsSvg } from 'pages/ApartmentsIdPage/AddTenantModal/img/dots_v.svg'
// import { ModalWindow } from 'components/Modal/Modal'
import { useInView } from 'react-intersection-observer'

import { FinishChatModal } from 'pages/ChatPage/Chat/FinishChat/FinishChatModal'

import {
  finishChat,
  PAGE_SIZE_MESSAGES_IN_CHAT,
  updResponsibleForChat,
} from 'redux/features/chats/chatsApiReducer'
import { useSocket } from 'providers/socketProvider/useSocket'
import { fetchMoreMessages, sendMessageInChat } from 'providers/socketProvider/SocketProvider'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 460,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  padding: '24px',
  borderRadius: '24px',
  border: 'none',
}
interface ChatMessage {
  text: string
  date: string
  id: number
  author: number
  time?: any
}

interface ChatGroup {
  date: string
  messages: ChatMessage[]
}

interface Props {
  chat: any[]
}

function getUniqueById(array: any) {
  const uniqueMap = new Map()

  array.forEach((item: any) => {
    if (!uniqueMap.has(item.id)) {
      uniqueMap.set(item.id, item)
    }
  })

  return Array.from(uniqueMap.values())
}

export const MsgContainer = ({
  currentChat,
}: // currentChatName,
// chat,
// sendMessage,
// getCurrentChatMessages,
any) => {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    // threshold: 0,
    // root,
    rootMargin: '100px',
    threshold: 0.01,
  })
  const myId = useSelector((state: any) => state?.profileData?.id)
  const { socketRef } = useSocket()

  const currentChatInfo = useSelector((state: any) => state?.chat?.currentChatInfo)
  const {
    page: pageMessageStore,
    id: currentChatId,
    title: chatTile,
    messages: chat,
    count: totalMessagesCount,
  } = currentChatInfo
  // const chatRefFlatData = useSelector((state: any) => state?.chat?.chatRefFlatData)
  // const isMsgListReset = useSelector((state: any) => state?.chat?.isMsgListReset)
  // const countMsg = useSelector((state: any) => state?.chat?.countMsg)

  // // const chatReducer = useSelector((state: any) => state?.chat)

  // const [lastMsgIdForPagination, setLastMsgIdForPagination] = useState(null)
  // const [page, setPage] = useState(1)

  const groups = React.useMemo(() => {
    const groupsMap = new Map<string, any>()

    // setLastMsgIdForPagination(chat[0]?.id)
    console.log('chat*****', chat)

    // getUniqueById is used to avoid duplicated values
    // chat.forEach((message: any) => {
    getUniqueById(chat).forEach((message: any) => {
      const dateStr = moment(message.sent_at).format('DD MMMM YYYY') // formattedDate // date.toISOString().substr(0, 10)
      // console.log('ENTRY___groups^^^', dateStr)

      const group = groupsMap.get(dateStr) ?? {
        date: moment(message.sent_at).format('DD MMMM'),
        messages: [],
      }

      const utcTime = moment.utc(message.sent_at, 'YYYY-MM-DD HH:mm:ss')
      // Convert the UTC time to the local time zone
      const localTime = utcTime.local()

      group.messages.push({ ...message, time: moment(localTime).format('HH:mm') })
      groupsMap.set(dateStr, group)
    })

    // console.log('IDENTIFIER_', chat.length)
    // console.log('IDENTIFIER_', groupsMap)

    const groupedArrMsg = Array.from(groupsMap.values())
    // console.log('ENTRY___groups^^^', groupsMap)

    const monthMap: { [key: string]: number } = {
      января: 0,
      февраля: 1,
      марта: 2,
      апреля: 3,
      мая: 4,
      июня: 5,
      июля: 6,
      августа: 7,
      сентября: 8,
      октября: 9,
      ноября: 10,
      декабря: 11,
    }

    const parseDateToTimestamp = (dateStr: string): number => {
      // console.log('ENTRY___groups^^^', day, month, monthIndex, groupedArrMsg)
      const [day, month] = dateStr.split(' ')
      const monthIndex = monthMap[month]
      const date = new Date(2024, monthIndex, parseInt(day, 10))
      return date.getTime()
    }
    const parseSentAtToTimestamp = (sentAt: string): number => {
      return new Date(sentAt).getTime()
    }
    const formatDate = (date: Date): string => {
      const day = date.getDate()
      const month = Object.keys(monthMap).find((key) => monthMap[key] === date.getMonth())
      return `${day} ${month}`
    }

    groupedArrMsg.sort((a, b) => parseDateToTimestamp(a.date) - parseDateToTimestamp(b.date))

    // Sort messages within each object by sent_at
    groupedArrMsg.forEach((item) => {
      item.messages.sort(
        (a: any, b: any) => parseSentAtToTimestamp(a.sent_at) - parseSentAtToTimestamp(b.sent_at),
      )
    })

    // Convert back to the desired format
    groupedArrMsg.forEach((item) => {
      const date = new Date(parseDateToTimestamp(item.date))
      item.date = formatDate(date)
    })

    const groupedArrMsgSorted = groupedArrMsg

    console.log('SOCKET: groupedArrMsgSorted', groupedArrMsgSorted[groupedArrMsgSorted.length - 1])

    // Logig to trigger load more messages instead of h1 trigger
    if (groupedArrMsgSorted.length > 0) {
      if (groupedArrMsgSorted.length > 2) {
        groupedArrMsgSorted[1].ref = ref
      } else {
        groupedArrMsgSorted[0].ref = ref
      }
      // groupedArrMsgSorted[groupedArrMsgSorted.length - 1].ref = ref
    }

    return groupedArrMsgSorted
  }, [chat])

  const [isSearch, setIsSearch] = useState<boolean>(false)

  // const [searchText, setSearchText] = useState('')
  // const msgTxt = useRef<any>()
  // const [msgTxtCount, setMsgTxtCount] = useState('')

  // useEffect(() => {
  //   if (msgTxt.current) {
  //     msgTxt.current.value = ''
  //   }
  // }, [chatRefFlatData?.building])

  // const onSubmitMsg = (e: any, attachments: any) => {
  //   if (attachments) {
  //     sendMessage(msgTxt.current.value, attachments)
  //   } else {
  //     e.preventDefault()
  //     if (msgTxt?.current?.value?.trim() === '') return null
  //     sendMessage(msgTxt.current.value, null)
  //     msgTxt.current.value = ''
  //     // setIsOpenController(false)
  //     setOpen(false)
  //   }
  // }

  const innerRef = useRef<any>(null)

  // const [isFirstRenderForScroll, setIsFirstRenderForScroll] = useState(true)
  // const [prevChat, setPrevChat] = useState(null)

  // // useEffect(() => {
  // //   if (!prevChat) {
  // //     setPrevChat(currentChat)
  // //     console.log('currentChat/**/*/', prevChat, currentChat)
  // //   }

  // //   if (prevChat !== currentChat) {
  // //     setPrevChat(currentChat)
  // //     setIsFirstRenderForScroll(true)
  // //   }

  // //   if (isFirstRenderForScroll) {
  // //     innerRef?.current?.scrollTo({
  // //       top: innerRef?.current?.scrollHeight,
  // //       behavior: 'smooth',
  // //     })
  // //   }
  // //   setTimeout(() => {
  // //     setIsFirstRenderForScroll(false)
  // //   }, 3000)
  // // }, [currentChat])

  // useEffect(() => {
  //   if (!prevChat) {
  //     setPrevChat(currentChat)
  //   }

  //   if (prevChat !== currentChat) {
  //     setPage(1)
  //     setPrevChat(currentChat)
  //     setIsFirstRenderForScroll(true)
  //   }

  //   if (page == 1) innerRef?.current?.scrollTo(0, innerRef?.current?.scrollHeight)

  //   // #START Ниже рабочи вариант scrollTO
  //   // if (isFirstRenderForScroll) {
  //   //   // innerRef?.current?.scrollTo({
  //   //   //   top: innerRef?.current?.scrollHeight,
  //   //   //   behavior: 'smooth',
  //   //   // })
  //   //   innerRef?.current?.scrollTo(0, innerRef?.current?.scrollHeight)
  //   // }
  //   // setTimeout(() => {
  //   //   setIsFirstRenderForScroll(false)
  //   // }, 3000)
  //   // #END - Ниже рабочи вариант scrollTO
  // }, [chat, groups])

  // const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setSearchText(event.target.value)
  // }

  // // const [isOpenController, setIsOpenController] = useState(false)

  // const [open, setOpen] = React.useState(false)
  // const handleOpen = () => setOpen(true)
  // const handleClose = () => setOpen(false)

  // const openImage = (url: string) => {
  //   console.log(url)
  // }
  const [updChat] = useUpdChatMutation()
  const dispatch = useDispatch()

  // // useEffect(() => {
  // //   // console.log('root_trackVisibility', root)
  // // }, [])

  // // useEffect(() => {
  // //   isMsgListReset == true && setPage(1)
  // // }, [isMsgListReset])

  // const myyRef = useRef<any>()
  // const [myElementIsVisible, setMyElementIsVisible] = useState<boolean>()

  // useEffect(() => {
  //   const pageSize = PAGE_SIZE_MESSAGES_IN_CHAT
  //   const count = countMsg
  //   const totalPages = Math.ceil(count / pageSize)

  //   if (isMsgListReset == true) {
  //     setPage(1)
  //   } else {
  //     if (page < totalPages) setPage(page + 1)
  //   }

  //   // const observer = new IntersectionObserver(
  //   //   (entries) => {
  //   //     const entry = entries[0]
  //   //     setMyElementIsVisible(entry.isIntersecting)
  //   //   },
  //   //   { threshold: -0.5 },
  //   // )
  //   // observer.observe(myyRef.current)
  // }, [myElementIsVisible])

  // useEffect(() => {
  //   // setPage((prev) => ++prev)
  //   // if(count > result.length) {
  //   const pageSize = PAGE_SIZE_MESSAGES_IN_CHAT
  //   const count = countMsg

  //   const totalPages = Math.ceil(count / pageSize)
  //   const currentPage = page

  //   const isLastPage = currentPage > totalPages

  //   // console.log(`#inView_INVIEW:${inView}, COUNT: ${count}, TOTAL: ${totalPages}, PAGE: ${page} `)
  //   if (!isLastPage) {
  //     getCurrentChatMessages(page)
  //   }
  // }, [page])

  useEffect(() => {
    if (!totalMessagesCount) {
      fetchMoreMessages(socketRef, pageMessageStore, PAGE_SIZE_MESSAGES_IN_CHAT, currentChatId)
    } else {
      console.log(
        'SOCKET: messages_data***HERE',
        currentChatId,
        inView,
        chat.length,
        totalMessagesCount,
        pageMessageStore,
      )
      if (currentChatId && inView && chat.length < totalMessagesCount) {
        fetchMoreMessages(
          socketRef,
          pageMessageStore + 1,
          PAGE_SIZE_MESSAGES_IN_CHAT,
          currentChatId,
        )
      }
    }
  }, [inView, currentChatId])

  // // useEffect(() => {
  // //   // First load
  // //   // if(!currentChatId)
  // // }, [pageMessageStore])
  // useEffect(() => {
  //   // First load
  //   if (page > 1) setPage(1)
  //   if (page === 1) {
  //     fetchMoreMessages(socketRef, page, PAGE_SIZE_MESSAGES_IN_CHAT, currentChatId)
  //     setPage((prev) => ++prev)
  //   }
  //   // if (currentChatId) {
  //   //   if (page > 1) {
  //   //     setPage(1)
  //   //     fetchMoreMessages(socketRef, page, PAGE_SIZE_MESSAGES_IN_CHAT, currentChatId)
  //   //   } else {
  //   //     fetchMoreMessages(socketRef, page, PAGE_SIZE_MESSAGES_IN_CHAT, currentChatId)
  //   //   }
  //   // }
  // }, [currentChatId])

  //  const currentChatInfo = useSelector((state: any) => state?.chat?.currentChatInfo)
  //  const { id: currentChatId } = currentChatInfo
  return (
    <div className={styles.msg_container}>
      {isSearch ? (
        <div
          className={cn(styles.header_msg_row, {
            [styles.header_search_msg_row]: isSearch,
          })}
        >
          <div className={styles.header_search_input}>
            <SearchIcon />

            <input
              name='search'
              placeholder='Поиск'
              // value={searchText}
              // onChange={handleSearchTextChange}
            />
          </div>

          <Button
            className={styles.send_arrow}
            appearance='container'
            disabled={false}
            onClick={() => setIsSearch(!isSearch)}
          >
            <CrossSvg />
          </Button>
        </div>
      ) : (
        <div className={styles.header_msg_row}>
          <div className={styles.header_title}>{chatTile}</div>
          <div
            style={{
              opacity: currentChatInfo.responsible_manager_id == myId ? 1 : 0,
              boxSizing: 'border-box',
            }}
          >
            {currentChatInfo?.chat_type !== 'appeal' &&
              // (currentChatInfo?.responsible_manager_id != myId ||
              //   currentChatInfo?.responsible_manager_id != null)
              currentChatInfo?.is_active !== false && (
                <FinishChatModal
                  onFinishHandler={() => {
                    const updChatData = {
                      chat_id: currentChatInfo?.reference_id,
                      title: currentChatInfo?.title,
                      status: ChatStatus.CLOSED,
                      is_active: false,
                      responsible: null,
                    }

                    // console.log('SOCKET: data _here Item currentChatInfo', currentChatInfo)
                    updChat(updChatData)
                      .then(() => {
                        dispatch(updResponsibleForChat(updChatData))
                      })
                      .then(() => {
                        dispatch(finishChat(currentChatInfo?.reference_id))
                      })
                  }}
                  btnTitle={
                    <Button
                      className={styles.cancel_chat_btn}
                      appearance='primary'
                      disabled={false}
                    >
                      Завершить чат
                      {/* {currentChatInfo?.reference_id} */}
                    </Button>
                  }
                />
              )}
          </div>
        </div>
      )}
      <div
        ref={innerRef}
        className={cn(styles.msg_list, {
          [styles.msg_list_active]: currentChat,
        })}
        style={{ position: 'relative' }}
      >
        {groups.reverse().map((group: any) => (
          <MessageItem key={group.id} group={group} />
        ))}
        {/* <h1
          ref={ref}
          style={{
            border: 'solid red 3px',
            opacity: 1,
          }}
          onClick={() => console.log('SOCKET: messages_data***', chat.length)}
        >
          TRIGGER chat.length: {chat.length} PAGE: {pageMessageStore} currentChat: {currentChatId}
        </h1> */}
      </div>

      {currentChatInfo?.id && currentChatInfo.responsible_manager_id === null && (
        <div className={styles.take_chat_wrapper}>
          {/* 
          NOTE: 
          - ЛЮБОЙ ЧАТ ДОСТУПЕН ДЛЯ ВЗЯТИЯ В РАБОТУ когда исполнитель null (статус не влияет)
          - Статус чата закрыт = Всегда исполнитель null
          - Кнопка завершить чат влияет на reaponsible and status
          - Чат отображается в прочих: 
            responsible !== null && responsible != me : то для меня статус is_active == true 
          */}
          <TakeChatInProgress
            updChatData={{
              id: currentChatInfo?.id,
              chat_id: currentChatInfo?.reference_id,
              title: chatTile,
              responsible: myId,
              status: ChatStatus.OPEN,
            }}
          />
        </div>
      )}
      {currentChatInfo?.is_active && (
        <>
          {currentChatInfo.responsible_manager_id !== myId &&
            currentChatInfo.responsible_manager_id !== null && (
              <div className={styles.take_chat_wrapper}>
                <ChatAlreadyTaken />
              </div>
            )}
        </>
      )}
      {currentChatInfo.is_active && currentChatInfo.responsible_manager_id === myId && (
        <SendMsgContainer
        // msgTxt={'msgTxt'}
        // setMsgTxtCount={() => console.log}
        // currentChat={currentChat}
        // setOpen={() => console.log}
        // msgTxtCount={555}
        // handleOpen={() => console.log}
        // onSubmitMsg={() => console.log}
        // sendMessageEvent={sendMessage}
        // open={open}
        // handleClose={() => console.log}
        />
      )}

      {/* {currentChatInfo?.is_active && currentChatInfo.responsible_manager_id === myId && (
        <button onClick={() => console.log('currentChatInfo', currentChatInfo)}>
          SendMsgContainer
        </button>
      )} */}
      {/* <button onClick={() => console.log('currentChatInfo', currentChatInfo)}>
        SendMsgContainer
      </button> */}
    </div>
  )
}

const MessageItem = ({ group }: any) => {
  useEffect(() => {
    console.log('SOCKET: messages_data/////', group)
  }, [group])

  // const  = () => {
  // const group: any = {}
  return (
    <div key={group.date}>
      <div
        className={styles.group_date}
        ref={group.ref ? group.ref : null}
        // style={{ border: group.ref ? 'solid red' : 'solid green' }}
      >
        {group.date}
      </div>
      {group.messages.map((message: any, indx: number) =>
        message?.author?.role != 'tenant' ? (
          <div
            className={styles.msg_admin_container}
            key={message?.id}
            onClick={() => console.log('message', group)}
            // ref={group.ref ? group.ref : null}
            // style={{ border: group.ref ? 'solid red' : 'solid green' }}
          >
            <div className={styles.msg_admin}>
              <div className={styles.msg_time_grid}>
                {message?.author?.last_name && (
                  <div className={styles.msg_author_name} style={{ color: 'white' }}>
                    {`${message?.author.last_name} ${message?.author.first_name} ${message?.author.middle_name}`}
                  </div>
                )}

                {message?.attachments.length > 0 &&
                  (message?.attachments[0]?.extension == 'jpg' ||
                  message?.attachments[0]?.extension == 'png' ? (
                    <ImageFullScereen url={message?.attachments[0]?.url} />
                  ) : (
                    <a target='_blank' rel='noreferrer' href={message?.attachments[0]?.url}>
                      Файл
                    </a>
                  ))}
                <div className={styles.msg_txt}>
                  <div className={styles.msg_user_txt}>{message?.text}</div>
                </div>
                <div className={styles.msg_time_manager}>
                  {moment(message?.sent_at).format('HH:mm')}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className={styles.msg_user}
            key={message.id}
            onClick={() => console.log('message', group)}
            // ref={group.ref ? group.ref : null}
            // style={{ border: group.ref ? 'solid red' : 'solid green' }}
          >
            <div className={styles.msg_time_grid}>
              <div
                className={styles.msg_author_name}
              >{`${message.author.last_name} ${message.author.first_name} ${message.author.middle_name}`}</div>
              {message?.attachments.length > 0 &&
                (message?.attachments[0]?.extension == 'jpg' ||
                message?.attachments[0]?.extension == 'png' ? (
                  <ImageFullScereen url={message?.attachments[0]?.url} />
                ) : (
                  <a target='_blank' rel='noreferrer' href={message?.attachments[0]?.url}>
                    Файл
                  </a>
                ))}
              <div className={styles.msg_txt}>{message.text}</div>
              <div className={styles.msg_time}>{moment(message.sent_at).format('HH:mm')}</div>
            </div>
          </div>
        ),
      )}
    </div>
  )
}

// const SendMsgContainer = ({ onSubmitMsg }: any) => {
const SendMsgContainer = () => {
  const { socketRef } = useSocket()
  const [open, setOpen] = useState(false)
  const [msgTxt, setMsgTxt] = useState('')
  const currentChatInfo = useSelector((state: any) => state?.chat?.currentChatInfo)
  const { id: currentChatId } = currentChatInfo

  const onSubmitMsg = () => {
    setMsgTxt('')
    sendMessageInChat(socketRef, currentChatId, msgTxt)
    setOpen(!open)
  }

  const onSubmitMsgAttachment = (attachments: any) => {
    if (attachments) {
      sendMessageInChat(socketRef, currentChatId, msgTxt, attachments)
      setOpen(!open)
    }
  }

  return (
    <div className={styles.send_msg_row}>
      <SendFileClip
        sendMessageEvent={onSubmitMsgAttachment}
        isDisabled={false}
        btnTitle={
          <Button
            appearance='container'
            className={styles.btn_order}
            onClick={() => {
              console.log('LOGGGG:')
            }}
          >
            <ClipSvg />
          </Button>
        }
      />

      <textarea
        id='msg'
        name='msg'
        rows={3}
        value={msgTxt}
        onChange={(e) => {
          setMsgTxt(e.target.value)
        }}
        placeholder={currentChatId ? 'Напишите сообщение' : ''}
        onKeyDown={(e) => {
          if ((e.key === 'Enter' || e.keyCode === 13) && e.shiftKey) return null

          if (e.key === 'Enter' || e.keyCode === 13) {
            e.preventDefault()
            if (msgTxt.trim() === '') return null
            setOpen(true)
            return null
          }
        }}
        disabled={currentChatId ? false : true}
      />
      {msgTxt.length > 0 && (
        <>
          <div onClick={() => setOpen(!open)}>
            <Button
              className={styles.send_arrow}
              appearance='container'
              disabled={false}
              onClick={() => setOpen(true)}
            >
              <SendArrow />
            </Button>
          </div>
          <Modal
            open={open}
            onClose={() => setOpen(!open)}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Box sx={style}>
              <div className={styles.send_msg_modal_container}>
                <Title size='20px' margin={'0 0 32px 0'}>
                  Отправить это сообщение?
                </Title>
                <div className={styles.send_msg_actions_container}>
                  <Button
                    // className={styles.send_arrow}
                    appearance='primary-light'
                    disabled={false}
                    onClick={onSubmitMsg}
                  >
                    Да
                  </Button>
                  <Button
                    appearance='primary'
                    disabled={false}
                    onClick={() => {
                      setOpen(!open)
                    }}
                  >
                    Отмена
                  </Button>
                </div>
              </div>
            </Box>
          </Modal>
        </>
      )}
    </div>
  )
}

const TakeChatInProgress = ({ updChatData }: any) => {
  const [updChat] = useUpdChatMutation()
  const dispatch = useDispatch()
  const [isError, setIsError] = useState<boolean>(false)

  const handleClose = () => {
    setIsError(false)
  }

  return (
    <>
      {isError && (
        <Modal
          open={isError}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={style}>
            <div className={styles.smth_wrong_title}>
              <Title size='24px'>Не получилось взять чат в работу</Title>
            </div>
            <div className={styles.smth_wrong_descr}>
              Что-то пошло не так, пожалуйста, обновите страницу и попробуйте еще раз
            </div>
            <div className={styles.col_2}>
              <Button appearance='primary' onClick={handleClose} className={styles.smth_wrong_btn}>
                Понятно
              </Button>
            </div>
          </Box>
        </Modal>
      )}
      <div className={styles.take_chat_container}>
        <Title className={styles.take_chat_title}>
          Для того, чтобы написать в этот чат, вам нужно нажать на кнопку ниже
        </Title>
        <Button
          className={styles.take_chat_brn}
          appearance='primary'
          disabled={false}
          onClick={() => {
            updChat({ ...updChatData })
              .then((res: any) => {
                if (res?.data?.responsible) {
                  dispatch(
                    updResponsibleForChat({
                      ...updChatData,
                      responsible: res?.data?.responsible,
                      is_active: true,
                      status: ChatStatus.OPEN,
                      reference: res?.data?.reference,
                    }),
                  )
                }
              })

              .catch(() => {
                setIsError(true)
              })
          }}
        >
          Взять чат в работу
        </Button>
      </div>
    </>
  )
}

const ChatAlreadyTaken = () => {
  return (
    <div className={styles.take_chat_container}>
      <Title className={styles.take_chat_title}>Чат уже взят в работу другим менеджером</Title>
    </div>
  )
}

export const ImageFullScereen = ({ url }: any) => {
  const [isOpened, setIsOpened] = useState(false)
  return (
    <>
      {isOpened && (
        <div className={styles.img_full_container}>
          <img src={url} alt='' />

          <Button
            appearance='container'
            disabled={false}
            onClick={() => setIsOpened(!isOpened)}
            className={styles.cross}
          >
            <CrossSvg />
          </Button>
        </div>
      )}
      <img
        className={styles.img_to_open}
        src={url}
        alt=''
        // width={200}
        // height={200}
        onClick={() => setIsOpened(!isOpened)}
      />
    </>
  )
  return <div>{url}</div>
}
