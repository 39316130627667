import styles from './ChatPage.module.css'
import { useEffect, useRef, useState } from 'react'
import { ChatList } from './Chat/ChatList/ChatList'
import { MsgContainer } from './Chat/MsgContainer/MsgContainer'
import { ChatAdditionalInfo } from './Chat/ChatAdditionalInfo/ChatAdditionalInfo'
import io from 'socket.io-client'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentToken } from 'redux/features/auth/authSlice'
import { Button, Loader, LoaderScreenWrapper } from 'components'
import { LayoutChat } from 'layout/LayoutChat'

import { useGetFlatsQuery } from 'redux/features/flats/flatApiSlice'
import { useSocket } from 'providers/socketProvider/useSocket'
import { PAGE_SIZE_MESSAGES_IN_CHAT } from 'redux/features/chats/chatsApiReducer'

// export const PAGE_SIZE_MESSAGES_IN_CHAT = 24

export type ChatClientType = ReturnType<typeof io>
export const createChatClient = (jwt: string) => {
  return io(`${process.env.REACT_APP_CHAT_URL}`, {
    autoConnect: false,
    path: process.env.REACT_APP_CHAT_PATH ?? '/chat/socket.io',
    transports: ['websocket'],
    query: {
      Authorization: `Bearer ${jwt}`,
    },
  })
}

const ChatPage = () => {
  const { socketRef } = useSocket()
  const dispatch = useDispatch()

  const currentChatMsgStore = useSelector((state: any) => state?.chat?.currentChatMessage)
  const myId = useSelector((state: any) => state?.profileData?.id)
  const chatRefId = useRef(null)

  const setCurrentChatHandler = (id: any) => {
    chatRefId.current = id
  }

  const [initiatedId, setInitiatedId] = useState(null)
  const [currentChatName, setCurrentChatName] = useState('')
  const chatsListData = useSelector((state: any) => state?.chat?.chatsData)

  const [isConnected, setIsConnected] = useState(false)

  const token = useSelector(selectCurrentToken) || localStorage.token

  useEffect(() => {
    if (!token) {
      return
    }

    socketRef?.on('error', console.log)
    socketRef?.on('message_sent', messageSentLog)
    socketRef?.connect()
    setIsConnected(true)

    return () => {
      socketRef?.off('message_sent', messageSentLog)
      socketRef?.disconnect()
    }
  }, [token])

  const messageSentLog = (data: any) => {
    socketRef?.emit('messageSeen')
  }

  const getCurrentChatMessages = (page: number) => {
    socketRef?.emit('messages', {
      page,
      page_size: PAGE_SIZE_MESSAGES_IN_CHAT,
      chat_id: chatRefId.current,
    })
  }

  // MsgContainer Logic
  if (isConnected == false)
    return (
      <LoaderScreenWrapper>
        <div className={styles.test_socket_conatiner}>
          <Loader />
          <small className={styles.test_socket}>
            Connection - {isConnected ? 'true' : 'false'}
          </small>
        </div>
      </LoaderScreenWrapper>
    )

  return (
    <>
      <div className={styles.messenger_container}>
        <ChatList />
        <MsgContainer />
        <ChatAdditionalInfo />
        {/*  
          <MsgContainer
            getCurrentChatMessages={getCurrentChatMessages}
            myId={myId}
            chat={currentChatMsgStore}
            currentChatName={currentChatName}
            currentChat={chatRefId.current}
            sendMessage={sendMessage}
          />
          <ChatAdditionalInfo
            data={[]}
            initiatedId={initiatedId}
            setCurrentChatToNull={() => {
              chatRefId.current = null
            }}
          /> 
        */}
      </div>
    </>
  )
}

export default LayoutChat(ChatPage)
