import { Button, Title } from 'components'
import styles from './FinishChatModal.module.css'
import { useState } from 'react'
import * as React from 'react'
import Modal from '@mui/material/Modal'
import { Box } from '@mui/material'
import classNames from 'classnames'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  // width: 540,
  // boxSizing: 'border-box',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  padding: '24px',
  borderRadius: '8px',
  border: 'none',
}

export const FinishChatModal = ({
  btnTitle,
  onFinishHandler,
}: {
  btnTitle: React.ReactElement
  refetch?: any
  onFinishHandler?: any
}) => {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  // React.useEffect(() => {}, [])

  return (
    <>
      <div onClick={handleOpen}>{btnTitle}</div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        {/* <Box sx={style} component='form' noValidate autoComplete='off'> */}
        <Box sx={style}>
          <div className={styles.main_container}>
            <div className={styles.title_container}>
              <Title className={styles.title}>Вы уверены, что хотите завершить чат?</Title>
            </div>
            <div className={styles.description}>
              Чат будет перемещен в раздел “Прочие” и доступен для чтения. Вы или другие менеджеры
              сможете писать в него сообщения после нажатия на кнопку “Взять чат в работу”
            </div>

            <div className={styles.col_2_percentage}>
              <Button
                appearance='outlined'
                onClick={() => {
                  onFinishHandler()
                  handleClose()
                }}
                className={classNames(styles.btn_grey, styles.btn)}
              >
                Завершить чат
              </Button>

              <Button appearance='primary' onClick={handleClose} className={styles.btn}>
                Продолжить общение
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  )
}
