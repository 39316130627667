/**
 * Socket Provider
 */
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentToken } from 'redux/features/auth/authSlice'
import {
  PAGE_SIZE_PREVIEW_IN_CHAT,
  setAddIncomingMsgInChat,
  setChatList,
  setChatStatusNewSockets,
  setCurrentChat,
} from 'redux/features/chats/chatsApiReducer'
// import { setCurrentMsgListInStore } from 'redux/features/chats/chatsApiReducerOLD'

import { useGetChatsListQuery, useLazyGetChatsListQuery } from 'redux/features/chats/chatsApiSlice'
import { useGetProfileQuery } from 'redux/features/profile/profileApiSlice'
import io from 'socket.io-client'

export const SocketContext: any = React.createContext({ socketRef: null })
export type ChatClientType = ReturnType<typeof io>
export const createChatClient = (jwt: string) => {
  return io(`${process.env.REACT_APP_CHAT_URL}`, {
    autoConnect: false,
    path: '/chat/socket.io',
    transports: ['websocket'],
    reconnection: true,
    reconnectionDelay: 1000,
    query: {
      Authorization: `Bearer ${jwt}`,
    },
  })
}

export const fetchMoreChatList = (
  socketRef: any,
  page: number,
  page_size: number,
  is_active: boolean,
  search_string?: string,
) => {
  console.log('8SOCKET: new_data ss', search_string)
  socketRef?.emit('preview', {
    page,
    page_size,
    is_active,
    search_string,
    chat_type: ['support', 'appeal'],
  })
}

export const fetchMoreMessages = (
  socketRef: any,
  page: number,
  page_size: number,
  chat_id: number | string,
) => {
  socketRef?.emit('messages', {
    page,
    page_size,
    chat_id,
  })
}

export const sendMessageInChat = (
  socketRef: any,
  chat_id: number | string,
  msg: any,
  attachments?: any,
) => {
  if (attachments) {
    socketRef?.emit('message_sent', {
      chat_id,
      message: '',
      // message: 'Файл',
      attachments_ids: attachments,
    })
  } else {
    socketRef?.emit('message_sent', { chat_id, message: msg })
  }
}

export const setMessageSeen = (socketRef: any, message_id: number | string) => {
  // console.log('ACC Event performed correctly:уауау', message_id)

  socketRef.emit('message_seen', { message_id })
  // socketRef.emit('message_seen', { message_id }, (response: any) => {
  //   if (response.status === 'ok') {
  //     console.log('ACC Event performed correctly:', response)
  //   } else {
  //     console.error('ACC Error occurred:', response.error)
  //   }
  // })
}

export const SocketProvider = ({ children }: any) => {
  const dispatch = useDispatch()
  const token = useSelector(selectCurrentToken) || localStorage.token

  const { data: profileData } = useGetProfileQuery()
  const pofileRef = useRef<any>(null)

  // Keep profile info for socket
  useEffect(() => {
    pofileRef.current = profileData
  }, [profileData])

  // if (!token) return <></>
  const [isConnected, setIsConnected] = useState(false)
  const socketRef = useRef<ChatClientType | null>(null)
  const chatListInfo = useSelector((state: any) => state?.chat?.chatListInfo)
  const { is_active } = chatListInfo
  // const currentChat = useSelector((state: any) => state?.chat?.chatRefFlatData)
  const currentChatInfo = useSelector((state: any) => state?.chat?.currentChatInfo)
  const { id: currentChatId } = currentChatInfo
  useEffect(() => {
    // if (isConnected) return
    if (!token) return

    socketRef.current = createChatClient(token)

    socketRef?.current?.on('messages_data', (data: any) => {
      data?.results && dispatch(setCurrentChat(data))
    })

    fetchMoreChatList(socketRef?.current, 1, PAGE_SIZE_PREVIEW_IN_CHAT, true)

    socketRef.current?.on('chats_data', (data) => {
      console.log('SOCKET: new_data', data)
      const { count, results, total_unseen_messages_count } = data
      dispatch(
        setChatList({
          count,
          chat_list: results,
          total_unseen_messages_count,
        }),
      )
    })

    socketRef.current?.on('message_new', (data: any) => {
      console.log('message_new', data)
      dispatch(setAddIncomingMsgInChat(data))
      // socketRef?.current?.emit('preview', { page: 1, page_size: PAGE_SIZE_PREVIEW_IN_CHAT })
      // dispatch(updChatsPreviewData(data))

      if (Number(data?.chat_id) == Number(currentChatId)) {
        // socketRef?.current?.emit('message_seen', { message_id: data?.id })
        // setMessageSeen(socketRef?.current, data?.id)
      }
    })

    socketRef.current?.on('chat_status_new', (data) => {
      console.log('SOCKET: chat_status_new', data)

      // chat_type: 'support'
      // flat_id: 389
      // id: 63726
      // is_active: false
      // last_message: null
      // reference_id: 492
      // responsible_manager_id: 6
      // title: 'Аквамарин Макарова 12 с4 к3, подъезд 2, кв 2'

      dispatch(setChatStatusNewSockets({ ...data, me: pofileRef.current?.id }))
    })

    // socketRef.current?.on('unseen_messages_counter', console.log)
    // socketRef.current?.on('error', console.log)

    // socketRef.current?.on('preview', previewLog)

    socketRef.current.connect()
    setIsConnected(true)

    return () => {
      socketRef.current?.removeAllListeners()
      socketRef.current?.disconnect()
      socketRef.current?.disconnect()
      socketRef.current = null
    }
  }, [token])

  // const { data: chatsDataFSMSearchApi, isSuccess: isSuccessChatsDataFSMSearch } =
  // const { data: chatsDataFSMSearch, isSuccess: isSuccessChatsDataFSMSearch } = useGetChatsListQuery(
  //   {
  //     query: searchWordChatList,
  //     status: is_active,
  //     chat_type: ['support, appeal'],
  //   },
  // )

  // const [getChatsListQuery, { data: chatsDataFSMSearch, isSuccess: isSuccessChatsDataFSMSearch }] =
  //   useLazyGetChatsListQuery()

  // useEffect(() => {
  //   if (token) console.log('TOKEN TUT')
  //   getChatsListQuery({
  //     query: searchWordChatList,
  //     // status: is_active, // TODO ВЕРНУТЬ когда буду фильтры
  //     chat_type: ['support, appeal'],
  //   })
  // }, [token])

  // const [chatsDataFSMSearch, setChatsDataFSMSearch] = useState([])

  // useEffect(() => {
  //   console.log('chatsDataFSMSearchApi', chatsDataFSMSearchApi)
  //   setChatsDataFSMSearch(
  //     chatsDataFSMSearchApi?.filter((chat: any) => chat.chat_type !== 'channel'),
  //   )
  // }, [chatsDataFSMSearchApi])

  // useEffect(() => {
  //   chatRefId.current = currentChat?.chatsDataFSM?.reference
  // }, [currentChat])

  // const messageNewLog = (data: any) => {
  //   console.log('chatStatusNew 9243031', data)

  //   socketRef?.current?.emit('preview', { page: 1, page_size: PAGE_SIZE_PREVIEW_IN_CHAT })
  //   dispatch(setAddIncomingMsgInChat(data))
  //   dispatch(updChatsPreviewData(data))

  //   if (Number(data?.chat_id) == Number(chatRefId.current)) {
  //     socketRef?.current?.emit('message_seen', { message_id: data?.id })
  //   }
  // }

  // const [socketChatsData, setSocketChatsData] = useState<any>(null)
  // const totalMsgNum = useSelector((state: any) => state?.chat?.totalMsgNum)

  // useEffect(() => {
  //   let combinedData: any = []

  //   if (chatsDataFSM?.length > 0 && socketChatsData?.results?.length) {
  //     combinedData = socketChatsData?.results?.reduce((acc: any, current: any, index: any) => {
  //       const indexFSM = chatsDataFSM.findIndex(
  //         (obj: any) => Number(obj.reference) === Number(current.id),
  //       )

  //       acc =
  //         indexFSM !== -1
  //           ? [
  //               ...acc,
  //               { ...current, chatsDataFSM: chatsDataFSM[indexFSM], indexFSM, current: current.id },
  //             ]
  //           : acc

  //       return acc
  //     }, [])

  //     // THIS IS  total unseen messages form referenced fsm chats, because in chats data totalValue is more
  //     const total_messages_count = combinedData?.reduce((accumulator: any, currentValue: any) => {
  //       return accumulator + currentValue.unseen_messages_count
  //     }, 0)

  //     dispatch(
  //       setChatsListData({
  //         chats: combinedData,
  //         // chats: combinedData?.filter(
  //         //   (chat: any) => chat?.chatsDataFSMSearch?.chat_type !== 'channel',
  //         // ),
  //         total_unseen_messages_count: socketChatsData?.total_unseen_messages_count,
  //         // total_unseen_messages_count: total_messages_count,
  //         // total_unseen_messages_count: totalMsgNum + total_messages_count,
  //         // total_unseen_messages_count: 788,
  //       }),
  //     )
  //   }
  // }, [socketChatsData, chatsDataFSM])

  // useEffect(() => {
  //   console.log('chatStatusNew socketChatsData', socketChatsData)
  // }, [socketChatsData])

  // useEffect(() => {
  //   console.log('9243031 GGGGOO3334', socketChatsData)

  //   if (chatsDataFSMSearch?.length == 0) {
  //     dispatch(
  //       setChatsListSearchData({
  //         chats: [],
  //       }),
  //     )

  //     return
  //   }

  //   let combinedData: any = []

  //   if (chatsDataFSMSearch?.length > 0 && socketChatsData?.results?.length) {
  //     combinedData = socketChatsData?.results.reduce((acc: any, current: any, index: any) => {
  //       const indexFSM = chatsDataFSMSearch.findIndex(
  //         (obj: any) => Number(obj.reference) === Number(current.id),
  //       )

  //       acc =
  //         indexFSM !== -1
  //           ? [
  //               ...acc,
  //               {
  //                 ...current,
  //                 chatsDataFSMSearch: chatsDataFSMSearch[indexFSM],
  //                 indexFSM,
  //                 current: current.id,
  //               },
  //             ]
  //           : acc

  //       return acc
  //     }, [])

  //     // THIS IS  total unseen messages form referenced fsm chats, because in chats data totalValue is more
  //     const total_messages_count = combinedData
  //       // ?.filter((item: any) => {
  //       //   return item?.chatsDataFSMSearch?.chat_type !== 'channel'
  //       // })
  //       ?.reduce((accumulator: any, currentValue: any) => {
  //         return accumulator + currentValue.unseen_messages_count
  //       }, 0)
  //     // console.log('77combinedData', combinedData)

  //     dispatch(
  //       // Делаю отдельно потому что должны фильтровать с сокетами и никак не сделать сейчас пагинацию если фильтровать основной список
  //       setChatsListSearchData({
  //         chats: combinedData,
  //         // chats: combinedData?.filter(
  //         //   (chat: any) => chat?.chatsDataFSMSearch?.chat_type !== 'channel',
  //         // ),
  //         // chats: [],
  //         // total_unseen_messages_count: total_messages_count,
  //         total_unseen_messages_count: socketChatsData?.total_unseen_messages_count,
  //       }),
  //     )
  //   }
  // }, [socketChatsData, chatsDataFSMSearch, isSuccessChatsDataFSMSearch])

  // const onChatsData = (data: any) => {
  //   console.log('chatStatusNew 2601532', data)

  //   dispatch(setTotalPagesOnSockets(Math.ceil(data?.count / PAGE_SIZE_PREVIEW_IN_CHAT)))
  //   dispatch(setTriggerPage(data?.results))

  //   socketChatsData
  //     ? setSocketChatsData((prev: any) => {
  //         return {
  //           ...prev,
  //           // set only unique chats
  //           results: [...data.results, ...prev.results].reduce((acc, current) => {
  //             if (!acc.some((item: any) => item.id === current.id)) {
  //               acc.push(current)
  //             }
  //             return acc
  //           }, []),
  //         }
  //       })
  //     : setSocketChatsData(data)
  // }

  // const previewLog = (data: any) => {
  //   console.log('TE_SAM_chats data.results PREV', data)
  // }

  // // const currentChatMsgStore = useSelector((state: any) => state?.chat?.currentChatMessage)
  // const chatStatusNew = (data: any) => {
  //   console.log('chatStatusNew8', pofileRef.current)
  //   dispatch(setChatStatusNewSockets({ ...data, me: pofileRef.current?.id }))
  // }

  // if (!isConnected) return <>СОКЕТЫ ОТВАЛИЛИСЬ</>
  return (
    <SocketContext.Provider value={{ socketRef: socketRef.current, isConnected }}>
      {children}
    </SocketContext.Provider>
  )
}
